import { t } from '@lib/i18n';
import { Trans } from 'react-i18next';
import { CompanyPrivacyLink } from '@entities/company';
import Typography, { getTypographyClass } from '@ui/Typography';
import { memo } from 'react';

const FormFooter = () => (
  <Typography variant="caption1" className="text-deep-blue-5 !mt-1">
    {/* <Trans */}
    {/*   i18nKey="contacts.footer" */}
    {/*   components={{ */}
    {/*     privacy-policy: ( */}
    {/*       <CompanyPrivacyLink */}
    {/*         className={getTypographyClass({ */}
    {/*           variant: 'link2', */}
    {/*           className: 'text-blue-2', */}
    {/*         })} */}
    {/*       /> */}
    {/*     ), */}
    {/*   }} */}
    {/* /> */}
    {t('contacts.footer')}
  </Typography>
);

export default memo(FormFooter);
